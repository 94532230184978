import { Container, Grid } from '@material-ui/core'
import React from 'react'
import BmCard from '../../components/bm-card'
import Layout from '../../components/layout'
import PageHeader from '../../components/page-header'
import GridRow from '../../components/_grid-row'

//education images
import greyPlaceholder from '../../images/grey-placeholder.png';

export default function Events() {
    return (
        <Layout pageName="Events">
            <PageHeader 
                header="Events"
                desc="We have yoga, we have pilates, we have cross-training...we have it all! Whether you're looking for general fitness, weight loss, or sports performance training, we have a class that will cater to your needs."
            />
            
            <Container maxWidth="xl">
                <GridRow>
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={greyPlaceholder} 
                                cardTitle={{title: 'Gouna Gets FIT season 5', titleLink: '/events/ggf-s5'}}  
                                cardSmallSubtitle="Gouna, September 12"
                                desc="Later this year...Join Gouna Gets FIT Season 5 scheduled for September 12! GGF is El Gouna's favorite 4 week lifestyle transformation challenge."  
                                learnMore={{text: 'Learn more', link: '/events/ggf-s5'}}
                            />
                        </Grid>
                        
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={greyPlaceholder}
                                cardTitle={{title: 'The Spinning Marathon', titleLink: '/events/spinning-marathon'}}  
                                cardSmallSubtitle="Sahel, August 13"
                                desc="Once again, The Fitness Playground brings to you a real party on wheels! 2 hours on the Spinning bike with master instructors, live music, sensational entertainment, and an endorphin-packed atmosphere guaranteed to keep your wheels turning strong until the very last hill. "  
                                learnMore={{text: 'Learn more', link: '/events/spinning-marathon'}}
                            />
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <BmCard 
                                img={greyPlaceholder}
                                cardTitle={{title: 'Masyaf Fitness Fiesta', titleLink: '/events/masyaf-fitness-fiesta'}} 
                                cardSmallSubtitle="Sahel, August 20" 
                                desc="The shores of Masyaf Ras Alhekma will host Sahel's biggest fitness fiesta showcasing the best coaches, fitness programs and an adrenaline infused afternoon! Get ready to sweat it with intention. "  
                                learnMore={{text: 'Learn more', link: '/events/masyaf-fitness-fiesta'}}
                            />
                        </Grid>
                    </Grid>
                </GridRow>
            </Container>
        </Layout>
    )   
}
